import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { Suspense, lazy } from 'react'
import styled from 'styled-components'

const Account = lazy(() => import('./Account/Account'));
const AdminTool = lazy(() => import('./AdminTool'));
const Affiliate = lazy(() => import ('./Affiliate/Affiliate'));
const AffiliateStatusCheck = lazy(() => import('./Affiliate/AffiliateStatusCheck'));
const Cancel = lazy(() => import('./Cancel/Cancel'));
const ChangeTier = lazy(() => import('./ChangeTier/ChangeTier'));
const CheckOut = lazy(() => import('./CheckOut/CheckOut'));
const Contact = lazy(() => import('./Contact'));
const Landing = lazy(() => import('./Landing/Landing'));
const LogIn = lazy(() => import('./LogIn/LogIn'));
const Nav = lazy(() => import('./Nav/Nav'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'));
const SavedScripts = lazy(() => import('./SavedScripts/SavedScripts'));
const Scripter = lazy(() => import('./Scripter/Scripter'));
const SignUp = lazy(() => import('./SignUp/SignUp'));
const Subscription = lazy(() => import('./Subscription/Subscription'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const UpdatePaymentMethod = lazy(() => import('./UpdatePaymentMethod/UpdatePaymentMethod'));
const Unsubscribe = lazy(() => import('./Unsubscribe/Unsubscribe'));

export default function App() {

  return (
    <Suspense fallback={null}>
    <All>
    <Router>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/account' element={<Account/>}/>
        {/* <Route path='/admin' element={<AdminTool/>}/> */}
        <Route path='/affiliate' element={<Affiliate/>}/>
        <Route path='/cancel' element={<Cancel/>}/>
        <Route path='/changetier' element={<ChangeTier/>}/>
        <Route path='/checkout' element={<CheckOut/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/login' element={<LogIn/>}/>
        <Route path='/resetpassword' element={<ResetPassword/>}/>
        <Route path='/saved' element={<SavedScripts/>}/>
        <Route path='/history' element={<SavedScripts/>}/>
        <Route path='/new' element={<Scripter/>}/>
        <Route path='/signup' element={<SignUp/>}/>
        <Route path='/subscription' element={<Subscription/>}/>
        <Route path='/terms' element={<TermsOfService/>}/>
        <Route path='/updatepaymentmethod' element={<UpdatePaymentMethod/>}/>
        <Route path='/unsubscribe' element={<Unsubscribe/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>

      {/* Navbar on all pages */}
      <Nav/>

      {/* Check if the logged in user is eligible to sign 
      up as an affiliate and display sign up link */}
      <AffiliateStatusCheck/>

    </Router>
    </All>
    </Suspense>
  );
}

const All = styled.div`
  padding-top: 103px;
  @media(min-width: 510px) {
    padding-top: 92px;
  }
`;